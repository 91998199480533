import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uiTruncateCenterWithEllipsis',
})
export class TruncateCenterWithEllipsisPipe implements PipeTransform {
  /**
   * @description
   * It takes a string, truncates it in the middle if it exceeds the threshold and adds the defined ellipsis
   * @param {string} text - The text to truncate
   * @param {number} [truncateThreshold] - The maximum length of the string before it gets truncated.
   * @param {string} [ellipsis] - The string to use as the ellipsis.
   * @returns {string} A string that is truncated to the length of the provided truncateThreshold.
   */
  transform(text: string, truncateThreshold: number = 16, ellipsis: string = '…'): string {
    const minimumTruncateLength: number = ellipsis.length + 2;

    if (truncateThreshold < minimumTruncateLength) {
      throw new Error(
        `Invalid configuration: Please define a minimum truncateThreshold of ${minimumTruncateLength} with your defined ellipsis '${ellipsis}'`,
      );
    }

    if (text.length > truncateThreshold) {
      const hasEvenSplit: boolean = (truncateThreshold - ellipsis.length) % 2 === 0;
      const preTruncateLength: number = Math.ceil(truncateThreshold / 2 - ellipsis.length / 2);
      const postTruncateLength = text.length - preTruncateLength + (hasEvenSplit ? 0 : 1);

      return text.substring(0, preTruncateLength).trim() + ellipsis + text.substring(postTruncateLength).trim();
    }

    return text;
  }
}
